import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';
import ClipMultimedia from '../clip-multimedia/clip-multimedia';

const namespaceIcon = 'clip-picture-icon';

const Figure = props => <ClipMultimedia {...props} />;

const Icon = ({ className }) => (
  <Image className={classnames(className, namespaceIcon)} alt="clip-icon" lazyload="off" src="picture-play.svg" />
);

Icon.propTypes = {
  className: string,
};

Icon.defaultProps = {
  className: '',
};

Figure.propTypes = {};

Figure.defaultProps = {};

const FigureMemo = React.memo(Figure);
const Preview = Figure;

export { FigureMemo, Icon, Preview };
